import { faBuilding, faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faBuildingCircleXmark,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import type { ComponentProps, PropsWithChildren } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { useAnimate } from "../../hooks";
import { useGetUser } from "../../services";
import { tabsState } from "../../state/tabs";
import { IconButton } from "../buttons";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerTitle,
  DrawerTrigger,
} from "../drawer";
import { ClientButtonLink } from "./ClientButtonLink";
import { MemberButtonLink } from "./MemberButtonLink";
import { NavigationDrawerHeader } from "./NavigationDrawerHeader";
import { NavigationDrawerRow } from "./NavigationDrawerRow";
import { getBaseUrl } from "./utils";
import { getImageUrl } from "../../utils/image";

interface Props {
  organizationId: string;
  organizationLogo?: string;
  userImage?: string;
}

export const NavigationDrawer = ({
  organizationId,
  organizationLogo,
  userImage,
}: Props) => {
  const tabsSnap = useSnapshot(tabsState);
  const baseUrl = getBaseUrl(organizationId);
  const navigate = useNavigate();
  const { data: user } = useGetUser();

  const orgLogoImage = (orgLogoId: string | undefined) =>
    orgLogoId ? getImageUrl(orgLogoId) : undefined;

  return (
    <Drawer direction="right" shouldScaleBackground={false}>
      <DrawerTrigger asChild>
        <IconButton icon={faEllipsis} />
      </DrawerTrigger>
      <DrawerContent className="min-w-[80%] max-w-[80%]" direction="right">
        <VisuallyHidden.Root>
          <DrawerTitle>Menu</DrawerTitle>
          <DrawerDescription>Options for the organization</DrawerDescription>
        </VisuallyHidden.Root>
        <NavigationDrawerHeader />
        <div className="w-full h-px bg-gray-2" />
        <div className="p-4">
          <NavigationDrawerRowLink
            href={baseUrl}
            label="Home"
            imageUrl={organizationLogo}
            alt="VOCS Studio"
          />
          <NavigationDrawerRowLink
            href={`${baseUrl}/members`}
            label="Members"
            icon={faUser}
          />
          {tabsSnap.members.length ? (
            <NavigationDrawerButtonContainer>
              {tabsSnap.members.map((memberId) => (
                <DrawerClose key={memberId} asChild>
                  <MemberButtonLink
                    onClick={() => navigate(`${baseUrl}/members/${memberId}`)}
                    organizationId={organizationId}
                    memberId={memberId}
                  />
                </DrawerClose>
              ))}
            </NavigationDrawerButtonContainer>
          ) : null}
          <NavigationDrawerRowLink
            href={`${baseUrl}/clients`}
            label="Clients"
            icon={faBuilding}
          />
          {tabsSnap.clients.length ? (
            <NavigationDrawerButtonContainer>
              {tabsSnap.clients.map((clientId) => (
                <DrawerClose key={clientId} asChild>
                  <ClientButtonLink
                    onClick={() => navigate(`${baseUrl}/clients/${clientId}`)}
                    organizationId={organizationId}
                    clientId={clientId}
                  />
                </DrawerClose>
              ))}
            </NavigationDrawerButtonContainer>
          ) : null}
          {user && (
            <NavigationDrawerRow
              label="Your Profile"
              imageUrl={userImage}
              alt="Profile"
              onClick={() =>
                navigate(
                  `/dash/${organizationId}/members/${user.profileSummary.id}`,
                )
              }
            />
          )}
          <NavigationDrawerRow label="Sign Out" icon={faBuildingCircleXmark} />
          {user.userOrganizations
            .filter((uo) => uo.organization.id !== organizationId)
            .map((userOrganization, index) => (
              <NavigationDrawerRow
                key={index}
                label={userOrganization.organization.name}
                imageUrl={orgLogoImage(
                  userOrganization.organization.iconLogoId,
                )}
                alt="Profile"
                onClick={() => {
                  window.location.href = `/dash/${userOrganization.organization.id}`;
                  localStorage.setItem(
                    "current-org-id",
                    userOrganization.organization.id,
                  );
                }}
              />
            ))}
        </div>
      </DrawerContent>
    </Drawer>
  );
};

NavigationDrawer.displayName = "NavigationDrawer";

const NavigationDrawerButtonContainer = ({ children }: PropsWithChildren) => {
  const [parent] = useAnimate();
  return (
    <ul
      ref={parent}
      className="w-full flex flex-row flex-wrap my-2 gap-1 items-center"
    >
      {children}
    </ul>
  );
};

const NavigationDrawerRowLink = ({
  href,
  ...props
}: ComponentProps<typeof NavigationDrawerRow> & {
  href: string;
}) => {
  const match = useMatch(href);
  return (
    <Link to={href}>
      <NavigationDrawerRow active={Boolean(match)} {...props} />
    </Link>
  );
};
